import { StaticImage } from "gatsby-plugin-image"

const NewHeroImage = () => {
  return <StaticImage 
    src="../svg/Academics.svg" 
    alt="main image life at campus image" 
    placeholder="blurred"
    layout="constrained"
    aspectRatio={16/9}
    height={400}/>
}

const FirstImage = () => {
    return <StaticImage 
      src="../svg/OPS.svg" 
      alt="illustrasi wisuda dengan toga" 
      placeholder="blurred"
      layout="constrained"
      // aspectRatio={16/9}
      height={400}/>
  }

  const SecondImage = () => {
    return <StaticImage 
      src="../svg/YOUTHMATTERS.svg" 
      alt="people working togeter remotely" 
      placeholder="blurred"
      layout="constrained"
      // aspectRatio={16/9}
      height={400}/>
  }

  const ThirdImage = () => {
    return <StaticImage 
      src="../svg/MYINTERNSHIP.svg" 
      alt="learning illustration while working" 
      placeholder="blurred"
      layout="constrained"
      // aspectRatio={16/9}
      height={400}/>
  }

export { NewHeroImage, FirstImage, SecondImage, ThirdImage }