import React from "react"
const Video = ({ videoSrcURL, videoTitle, ...props }) => (
    <div className="video container mx-auto text-center" style={{position:'relative', paddingTop:'56.25%'}}>
        <iframe
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        width="640" 
        height="360"
        style={{position:'absolute', top:0, left:0, width:'100%', height:'100%'}}
        />
  </div>
)
export default Video