import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import TestimoniCard from './TestimoniCard';
var chunk = require('lodash/chunk');

const MultiCarousel = ({ className, items }) => {
  const itemsChunk = chunk(items, 3)
  return (
    <Carousel className={className} wrap={false} variant="dark">
      {
        itemsChunk.map((items, index) => (
        <Carousel.Item key={index}>
          <div className="flex flex-col md:flex-row md:-mx-3">
              {items.map(customer => (
              <div key={customer.customerName} className="flex-1 px-3">
                <TestimoniCard customer={customer} />
              </div>
            ))}
          </div>
        </Carousel.Item>)
        )
      }
  </Carousel>
)};

export default MultiCarousel;