export default [
  {
    title: '“memberikan informasi & referensi yang bermanfaat”',
    content:
      `OPS memberikan saya informasi & referensi yang sangat bermanfaat untuk memilih jurusan di perkuliahan. Penyampaian informasi yang padat ditambah narasumber yang kompeten membuat kegiatan OPS menarik & menyenangkan.`,
    customerName: 'Muhammad Fajar Aji Dharma',
    customerTitle: 'Peserta OPS 2017',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: ' “dikenalkan pada prodi-prodi yang begitu beragam”',
    content:
      '	Pada saat mengikuti OPS, saya merasa dikenalkan pada prodi-prodi yang begitu beragam. Pemateri sangat kredibel, karena ahli di bidangnya. Sesi sharingnya bagus, penyampaiannya baik. Presentasinya membuat kita dapet ilmunya. Kita jadi berasa seperti mengeksplor prodi-prodi lain. Selain itu, disana juga bertemu kawan-kawan seperjuangan.',
    customerName: 'Muhammad Fajar Alfath',
    customerTitle: 'Peserta OPS 2017',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: '“membantu proses eksplorasi diri”',
    content:
      '	Acara OPS 2017 ini banyak membantu aku, terutama dalam proses eksplorasi diri. Dengan mengenal diri, aku bisa mengetahui minat dan bakat yang tentunya mempermudah aku memilih jurusan di perkuliahan.  Materi yang dipaparkan juga membuahkan banyak insight. Selain itu, disini aku bisa membangun relasi dengan peserta lainnya yang memiliki semangat yang sama.',
    customerName: 'Fathi Khatami Maulana',
    customerTitle: 'Peserta OPS 2017',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: '“Sangat seru dan informatif”',
    content:
      'Sangat seru dan Informatif. Saya mendapat wawasan yang luas mengenai program studi di acara ini, serta saya jadi bisa lebih sadar kelemahan dan kelebihan yang saya miliki, karena di acara ini saya diberi waktu untuk merenung dan mengingat apa yang ada dalam diri saya sebenarnya.',
    customerName: 'Naufal Prasidha',
    customerTitle: 'Peserta OPS 3.0 Tahun 2020',
    customerImage: 'https://placeimg.com/150/150/people'
  },{
    title: '“membuka pikiran saya.”',
    content:
      'Sangat bagus dan bermanfaat. Saya jadi tahu banyak program studi di Indonesia. Acara ini juga memperjelas tujuan saya ke depannya. Saya berterima kasih kepada acara ini dan seluruh panitianya. Acara ini telah sukses membuka pikiran saya.',
    customerName: 'Muhammad Gibran Widiharyanto',
    customerTitle: 'Peserta OPS 4.0 Tahun 2021',
    customerImage: 'https://placeimg.com/150/150/people'
  },{
    title: '“sangat seruu!”',
    content:
      'Walaupun saya masih belum bisa terlalu menentukan, khususnya ingin jurusan apa, tapi mindset akan jurusan-jurusan dan mengenal diri sendiri jauh lebih terbuka, sangat seruu!',
    customerName: 'Maryam Dewi Pusparini',
    customerTitle: 'Peserta OPS 3.0 Tahun 2020',
    customerImage: 'https://placeimg.com/150/150/people'
  },{
    title: '“sekarang saya bisa mengatur persiapan…”',
    content:
      'Mulai dari sekarang saya bisa mengatur persiapan apa yang akan saya lakukan nantinya, dan mengetahui bagaimana cara mem-planningnya.',
    customerName: 'Azzahra Trie Wulandari',
    customerTitle: 'Peserta OPS 4.0 Tahun 2021',
    customerImage: 'https://placeimg.com/150/150/people'
  },{
    title: '“Mengenali diri itu butuh proses…”',
    content:
      'Mengenali diri itu butuh proses dan jika kamu mempunyai suatu goals yang besar maka persiapkan dari sekarang.',
    customerName: 'Wulan Laili Rohmah',
    customerTitle: 'Peserta OPS 4.0 Tahun 2021',
    customerImage: 'https://placeimg.com/150/150/people'
  },{
    title: '“kuliah itu tidak sekedar mengejar ilmu…”',
    content:
      'Saya jadi mengetahui kalau kuliah itu tidak sekedar mengejar ilmu tapi juga untuk membesarkan minat dan bakat kita kita harus mencari circle pertemanan yang baik.',
    customerName: 'Raisha Anaya Narindra',
    customerTitle: 'Peserta OPS 4.0 Tahun 2021',
    customerImage: 'https://placeimg.com/150/150/people'
  },{
    title: '“Aku bakalan berubah juga menjadi lebih baik”',
    content:
      'Banyak dapet jawaban dari masing-masing peserta yang menanya kepada narasumber. Aku bakalan berubah juga menjadi lebih baik agar tidak stress.',
    customerName: 'Sofie Azkia Rukmini',
    customerTitle: 'Peserta Youth Matter Self Series #1',
    customerImage: 'https://placeimg.com/150/150/people'
  },{
    title: '“mudah dimengerti”',
    content:
      'Praktikal dan mudah dimengerti',
    customerName: 'Syifa Andini',
    customerTitle: 'Peserta Youth Matters Self Series #1',
    customerImage: 'https://placeimg.com/150/150/people'
  },{
    title: '“Sangat memotivasi…”',
    content:
      'Sangat memotivasi dan memberikan aura yang positif',
    customerName: 'Maritha Faiza',
    customerTitle: 'Peserta Youth Matters Self Series #1',
    customerImage: 'https://placeimg.com/150/150/people'
  },
];
