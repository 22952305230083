import React from 'react';
import { Button } from 'react-bootstrap';
import Card from '../components/Card';
import Carousel from '../components/MultiCarousel';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import testimonis from '../data/testimoni';
import { NewHeroImage, FirstImage, SecondImage, ThirdImage } from '../svg/NewHeroImage';
import Video from '../components/Video'

const Index = () => (
  <Layout>
    <section id="beranda" className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Jalani kehidupan kampusmu dengan seru!
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Temukan diskusi dan konsultasi menarik bersama kami seputar <strong>pemahaman diri dan kehidupan kampus</strong>. Hal-hal yang jarang diajarkan di kampus akan dibahas di sini.
          </p>
          <p className="mt-8 md:mt-12">
            <Button size="lg" onClick={() => window.location.href="#bergabung"}>
              Get Started
            </Button>
          </p>
          <p className="mt-4 text-gray-600">Mari Bergabung dalam acara kami</p>
        </div>
        <div className="lg:w-1/2">
          <NewHeroImage />
        </div>
      </div>
    </section>
    <section id="layanan" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Program-program kami</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">ORIENTASI PROGRAM STUDI</p>
              <p className="mt-4">
                Acara khusus untuk kamu yang ingin memilih jurusan sesuai diri.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">YOUTH MATTERS</p>
              <p className="mt-4">
                Pertemuan berseri yang membahas isu-isu penting anak muda.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">MY INTERNSHIP</p>
              <p className="mt-4">
                Magang yang ‘gue banget’ bisa kamu rasakan di sini. Tapi, kuota terbatas ya!
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <SplitSection
      id="kegiatan"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">ORIENTASI PROGRAM STUDI</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Rangkaian acara untuk memilih program studi bagi siswa-siswi SMA, terdiri dari pembekalan keterampilan diri, pengenalan dan perencanaan program studi.
          </p>
        </div>
      }
      secondarySlot={        
        <div>
          <ThirdImage />
        </div>
      }
    />
    <SplitSection
      primarySlot={
        <div>
          <FirstImage />
        </div>
      }
      secondarySlot={<div className="lg:pl-32 xl:pl-48">
      <h3 className="text-3xl font-semibold leading-tight">
        YOUTH MATTERS
      </h3>
      <p className="mt-8 text-xl font-light leading-relaxed">
      Diskusi online yang diadakan berkala membahas tema diri <em>(self series)</em>, kampus & karier <em>(campus & career series)</em> dan kehidupan sosial <em>(social life series)</em>.
      </p>
    </div>}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            MY INTERNSHIP
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Magang berbasis diri di Lingkar Bina Muda untuk individu yang ingin magang sesuai potensi dan keterampilan yang dimiliki.
          </p>
        </div>
      }
      secondarySlot={
        <div>
          <SecondImage />
        </div>
      }
    />
    <section id="manfaat" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Manfaat-Manfaat yang kamu dapat:</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="50%" secondaryText="Lebih paham diri sendiri" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="100%" secondaryText="Nuansa nyaman dan hangat" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="80%" secondaryText="Bimbingan yang mencerahkan" />
          </div>
        </div>
      </div>
    </section>
    <section id="video" className="py-20 lg:py-40">
      <div className="container mx-auto text-center">
        <Video videoSrcURL="https://www.youtube.com/embed?listType=playlist&list=PL_2WcEMo3EtfcCpjZuy0xhMgrwi8KhUVZ" videoTitle="Pengantar OPS" />
      </div>
    </section>
    <section id="testimoni" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">Kata orang tentang Kabima</LabelText>
        <Carousel items={testimonis}/>
      </div>
    </section>
    <section id="tanyaJawab" className="py-20 lg:py-40 text-center">
      <div className="container mx-auto">
        <Button size="lg" onClick={() => window.open('https://kabima-menjawab.glide.page')}>
            Buka Halaman Jawaban dari Para Expert
        </Button>
        <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
        <iframe class="airtable-embed airtable-dynamic-height" src="https://airtable.com/embed/shrWoWnaAcRbt7ViY?backgroundColor=green" frameborder="0" onmousewheel="" width="100%" height="947">
        </iframe>      
      </div>
    </section>
    <section id="bergabung" className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Mau bergabung Bersama kami?</h3>
      <p className="mt-8 text-xl font-light">
        Klik untuk bergabung dengan grup whatsapp atau telegram kami
      </p>
      <p className="mt-8">
        <Button size="lg" onClick={() => window.open('https://wa.me/message/EXS7BK5RZNYMG1')}>
          Whatsapp
        </Button>
      </p>
      <p className="mt-8">
        <Button size="lg" onClick={() => window.open('https://t.me/+Bl1hWpgU-wJiYWE1')}>
          Telegram
        </Button>
      </p>
    </section>
  </Layout>
);

export default Index;